<template>
  <title-wrapper :title="$t('components.manageSysadmins.title')">
    <my-data-table
      :data="sysadmin"
      :headers="headers"
      :loading="loading"
      :length="length"
      :page.sync="params.page"
      :sortBy="sortBy"
      :sortDesc="sortDesc"
      @update:sort-by="sortByFunc"
      @update:sort-desc="sortDescFunc"
    >
      <template v-slot:filters>
        <v-col cols="12" sm="2">
          <v-text-field
            v-model="params.firstName"
            hide-details
            :placeholder="$t('helpers.firstName')"
            solo
          ></v-text-field
        ></v-col>
        <v-col cols="12" sm="3">
          <v-text-field
            v-model="params.lastName"
            hide-details
            :placeholder="$t('helpers.lastName')"
            solo
          ></v-text-field
        ></v-col>
        <v-col cols="12" sm="3">
          <v-text-field
            v-model="params.email"
            hide-details
            :placeholder="$t('helpers.email')"
            solo
          ></v-text-field
        ></v-col>
        <v-col cols="12" sm="2">
          <v-text-field
            v-model="params.phone"
            hide-details
            :placeholder="$t('helpers.phoneNumber')"
            solo
          ></v-text-field
        ></v-col>
        <v-col cols="6" sm="1">
          <v-btn color="primary" elevation="0" fab dark block @click="onSearch">
            <v-icon>mdi-magnify</v-icon>
          </v-btn></v-col
        >
        <v-col cols="6" sm="1">
          <v-btn color="primary" elevation="0" fab dark block @click="clearParams">
            <v-icon>mdi-cancel</v-icon>
          </v-btn></v-col
        >
      </template>

      <template v-slot:actions="slotProps">
        <router-link :to="{ name: 'SingleSysadmin', params: { userId: slotProps.item._id } }">
          <v-list-item class="hover-item-popup">
            <v-list-item-content>
              {{ $t('components.manageSysadmins.showDetails') }}</v-list-item-content
            >
          </v-list-item>
        </router-link>
      </template>

      <template v-slot:below>
        <v-row
          ><v-col><v-divider></v-divider></v-col
        ></v-row>
        <v-row
          ><v-col
            ><primary-button :to="{ name: 'AddSysadmin' }"
              ><v-icon class="mr-4">mdi-plus-circle-outline</v-icon
              >{{ $t('components.manageSysadmins.addAdmin') }}</primary-button
            ></v-col
          ></v-row
        >
      </template>
    </my-data-table>
  </title-wrapper>
</template>
<script>
import TitleWrapper from '../../../../components/TitleWrapper.vue';
import MyDataTable from '../../../../components/MyDataTable.vue';
import PrimaryButton from '../../../../components/buttons/PrimaryButton.vue';
import SysadminService from '../../../../services/SysadminService';
import { sortHandler } from '../../../../mixins/sortHandler';
import VueI18n from '../../../../plugins/i18n';

export default {
  name: 'Admins',
  components: { TitleWrapper, MyDataTable, PrimaryButton },
  mixins: [sortHandler],
  data() {
    return {
      count: 0,
      itemsPerPage: 6,
      length: 0,
      sysadmin: [],
      loading: true,
      params: {
        email: '',
        phone: '',
        firstName: '',
        lastName: '',
        page: 1,
        limit: 6,
        sort: '',
      },
    };
  },
  computed: {
    headers() {
      return [
        {
          text: VueI18n.t('helpers.fullName'),
          align: 'start',
          value: 'fullName',
          sortable: false,
        },
        { text: VueI18n.t('helpers.email'), value: 'email', sortable: false },
        { text: VueI18n.t('helpers.phoneNumber'), value: 'phone' },
        { text: VueI18n.t('helpers.lastActive'), value: 'lastActive' },
        { text: '', value: 'actions', sortable: false },
      ];
    },
  },
  watch: {
    'params.page': function () {
      this.getFiltered();
    },
  },
  methods: {
    clearParams() {
      this.params = {
        email: '',
        phone: '',
        firstName: '',
        lastName: '',
        page: 1,
        limit: 6,
        sort: '',
      };
      this.getFiltered();
    },
    onSearch() {
      this.params.page = 1;
      this.getFiltered();
    },
    async getFiltered() {
      try {
        const response = await SysadminService.getAllSysadmins(this.params);
        this.sysadmin = response.data.map((sysadmin) => {
          sysadmin.fullName = `${sysadmin.firstName} ${sysadmin.lastName}`;
          return sysadmin;
        });
        this.count = response.count;

        this.length = Math.ceil(response.count / this.itemsPerPage);
      } catch (error) {
        console.error(error);
      } finally {
        this.loading = false;
      }
    },
    fetchSort() {
      this.getFiltered();
    },
  },
  created() {
    this.getFiltered();
  },
};
</script>
<style lang="scss" scoped></style>
